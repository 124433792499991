import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import FaqPageTemplate from '../components/FaqPageTemplate'
import Layout from '../components/Layout'

const FaqPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <FaqPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        heading={frontmatter.heading}
        description={frontmatter.description}
        faq={frontmatter.faq}
      />
    </Layout>
  )
}

FaqPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        description
        faq {
          blurbs {
            image
            q
            a
          }
        }
      }
    }
  }
`
