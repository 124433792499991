import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Faq from '../Faq'
import { Link } from 'gatsby'

const FaqPageTemplate = ({ 
  title, 
  subtitle, 
  meta_title, 
  heading, 
  description, 
  meta_description, 
  faq 
}) => {
  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body pb-0'>
        <div className='column is-three-fifths is-offset-one-fifth'>
          <h1 className='title is-size-1 has-text-weight-bold'>
            {title}
          </h1>
        </div>
      </div>
    </section>

    <section className='section container'>

      <Faq gridItems={faq.blurbs} />

    </section>

  </div>
}

FaqPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  faq: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default FaqPageTemplate
