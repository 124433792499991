import React from 'react'
import PropTypes from 'prop-types'

const Faq = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <dl key={item.image} className='column is-4' style={{ borderRadius: '5px' }}>
        <section className='section'>
          <p className='is-size-2'>
            {item.image}
          </p>
          <dt className='has-text-weight-bold is-size-5 mb-3'>{item.q}</dt>
          <dd>{item.a}</dd>
        </section>
      </dl>
    ))}
  </div>
)

Faq.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      q: PropTypes.string,
      a: PropTypes.string,
    })
  ),
}

export default Faq
